import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// Set the document title
document.title = 'Unicorn Rescue';

const preloadImages = (imageUrls: string[]) => {
  imageUrls.forEach(url => {
    const img = new Image();
    img.src = url;
  });
};

const backgroundImages = [
  './images/forest-xl2.jpg',
  './images/mountain-xl2.jpg',
  './images/valley-xl3.jpg',
  './images/river-xl3.jpg',
  './images/wilderness-xl3.jpg',
  './images/castle-xl3.jpg',
  './images/meadow-xl5.jpg',
  // Add more image URLs as needed
];

preloadImages(backgroundImages);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
