import { useGame } from '../gameContext';

  
   const GameInfoModal = () => {
    
    const {gameInfoText, gameInfoModal, updateGameInfoModal} = useGame();

    const toggleModal = () => {
      updateGameInfoModal(!gameInfoModal);
  };
    
    if (!gameInfoModal) {
      return null; // Don't render the modal if it's not supposed to be shown
  }
   
    return (
     <div className="gameinfo">
      <h3>{gameInfoText}</h3> 

      <p>To Rescue the Unicorns from the Dragon, you must visit the unicorn meadow first!</p>
       
       
       <button onClick={toggleModal}>Close</button>
     </div>
    );
   };
   
   export default GameInfoModal;


