import { useContext } from 'react'
import { ScoreContext } from './ScoreContext';
import { useGame } from '../gameContext';
const ScoreCard = () => {

	const scoreContext = useContext(ScoreContext);
	if (!scoreContext) {
		throw new Error('ScoreContext is undefined');
	   }
	const { score } = scoreContext;

	
	const { difficulty } = useGame();



	return (
		<div className='scorecard'>
			<h3>{score} Unicorns Rescued this {difficulty} game</h3>
		</div>
	)
}

export default ScoreCard;
