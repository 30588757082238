interface ButtonProps {
  name: string;
  className: string;
  onClick: () => void;
 }
const Button = (props: ButtonProps) => {
  return (
      <button className={props.className} onClick={props.onClick}>{props.name}</button>
  );
};

export default Button;