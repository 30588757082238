import useGameBoard from '../hooks/useGameBoard'
import Button from "./Button"
import GameBoard from "./GameBoard"
import GameOverModal from "./GameOverModal"
import GameInfoModal from './GameInfoModal'
import ScoreCard from './Scorecard';
import { useGame } from '../gameContext';
import DifficultySelector from './DifficultySelector'

const Main = () => {
	const {
		moveKnight,
		startNewGame,
	} = useGameBoard();

	const { gameInfoModal, updateGameInfoModal, gameOver, gameArray, knightPosition, dragonPosition, unicorns } = useGame();


	return (
		<div className="gamecontainer">
			<div className="titleCard"></div>
			<div className='controls'> <Button className="startGame" name="Start a New Game" onClick={startNewGame} /> <DifficultySelector/>
			<ScoreCard />
			</div>
			{/* <Button name='Leaderboards'/> */}
			
			{gameOver && <GameOverModal />}
			{gameInfoModal && <GameInfoModal />}
			{!gameOver && <GameBoard
				gameArray={gameArray}
				knightPosition={knightPosition}
				unicorns={unicorns}
				dragonPosition={dragonPosition}
				moveKnight={moveKnight}
			/>}
		</div>
		
	);
};

export default Main;
