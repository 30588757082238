import './App.css';
import Main from './components/Main';
import { ScoreProvider } from './components/ScoreProvider';
import { GameProvider } from './gameContext';
function App() {
  return (
    <GameProvider>
    <ScoreProvider>
    <div className="App">
      <Main />
    </div>
    </ScoreProvider>
    </GameProvider>
  );
}

export default App;
