// moveDragon.ts
import { useCallback, useEffect, useRef, useContext } from 'react'
import { BoardLayout, Position, WildernessType, Unicorn } from '../types'
import { useGame } from '../gameContext';
import { useStartNewLevel } from './startNewLevel';



export const useMoveDragon = () => {
    const { unicorns, updateUnicorns, difficulty, gameArray, knightPosition, dragonPosition, updateGameOverReason, updateGameArray, updateDragonPosition, updateGameOver } = useGame();
    const { startNewLevel } = useStartNewLevel();
    const moveDragon = () => {

        const getPositionsAroundKnight = (knightPosition: Position): Position[] => {
            const [knightRow, knightCol] = knightPosition;
            const moves = [
                [-1, 0], [1, 0], [0, -1], [0, 1], // Up, Down, Left, Right
                [0, 0]  // Include the knight's position
            ];
            let positionsAroundKnight: Position[] = [];

            moves.forEach(([rowOffset, colOffset]) => {
                const newRow = knightRow + rowOffset;
                const newCol = knightCol + colOffset;

                // Check if the new position is within the board
                if (newRow >= 0 && newRow < 5 && newCol >= 0 && newCol < 5) {
                    positionsAroundKnight.push([newRow, newCol]);
                }
            });

            return positionsAroundKnight;
        };




        const getAllPositions = () => {
            const rows = Array.from({ length: 5 }, (_, i) => i);
            const cols = Array.from({ length: 5 }, (_, i) => i);
            return rows.flatMap((row) => cols.map((col) => [row, col]));
        };

        const getValidDragonPositions = (difficulty: string) => {
            const allPositions = getAllPositions();
            let validPositions = allPositions.filter(
                ([row, col]) => !(row === 0 && col === 0) && !(row === 4 && col === 4)
            );

            switch (difficulty) {
                case 'normal':
                    // Exclude current row and column, castle, and meadow
                    return validPositions.filter(
                        ([row, col]) => !(row === knightPosition[0] || col === knightPosition[1]) && !(row === 0 && col === 0) && !(row === 4 && col === 4)
                    );
                case 'hard':
                    // Exclude castle, meadow, and current position
                    return validPositions.filter(
                        ([row, col]) => !(row === 0 && col === 0) && !(row === 4 && col === 4) && !(row === dragonPosition[0] && col === dragonPosition[1])
                    );
                case 'challenge':
                    // Select a position from those near: the castle, the meadow, the knight
                    // You will need to define the positions near the castle, meadow, and knight
                    const positionsNearCastle = [[0, 1], [0, 2], [1, 1], [1, 2], [1, 3], [2, 1], [2, 2], [2, 3]];
                    const positionsNearMeadow = [[2, 3], [2, 4], [2, 2], [3, 2], [3, 3], [3, 4], [4, 3], [4, 2]];
                    const positionsNearKnight = getPositionsAroundKnight(knightPosition).filter(
                        ([row, col]) => !(row === 0 && col === 0) && !(row === 4 && col === 4)
                    ); // Calculate based on the current knight position
                    // Randomly select one of the three sets
                    const positionSets = [positionsNearCastle, positionsNearMeadow, positionsNearKnight];
                    const selectedSet = positionSets[Math.floor(Math.random() * positionSets.length)];

                    return validPositions = selectedSet;
                default:
                    return validPositions;
            }
        };


        if (!Object.keys(gameArray).length) {
            return;
        }

        const newGameBoard = gameArray;
        const currentDragonPosition = dragonPosition;


        const validDragonMoves = getValidDragonPositions(difficulty);
        const newDragonPosition = validDragonMoves[Math.floor(Math.random() * validDragonMoves.length)] as Position;

        const dragonAttacksKnight = () => {
            if (knightPosition[0] === newDragonPosition[0] && knightPosition[1] === newDragonPosition[1]) {
                const randomNumber = Math.random();
                if (randomNumber < 0.25) {
                    updateGameOverReason("The Dragon Hath Defeated Your Knight");
                    return updateGameOver(true); // End the game  25% of the time
                }
            }
        }

        const unicornAtDragonPosition = unicorns && unicorns.find(unicorn =>
            unicorn.position && unicorn.position.length >= 2 &&
            unicorn.position[0] === newDragonPosition[0] && unicorn.position[1] === newDragonPosition[1]
        );

        if (unicornAtDragonPosition) {
            console.log('unicorn at dragon position');
            const updatedUnicorns = unicorns.map(unicorn =>
                unicorn.position[0] === newDragonPosition[0] && unicorn.position[1] === newDragonPosition[1]
                    ? { ...unicorn, status: "eaten" }
                    : unicorn
            );
            console.log(JSON.stringify(updatedUnicorns, null, 2));
            // Update the unicorns
            updateUnicorns(updatedUnicorns);
            console.log('these unicorns should be updated with 1 eaten');
            console.log(JSON.stringify(unicorns, null, 2));
        }


        const allUnicornsEaten = unicorns.every(unicorn => unicorn.status === "eaten" );
        if (allUnicornsEaten) {
            updateGameOverReason("The Dragon Hath destroyed All The Unicorns");
            return updateGameOver(true);
        }

        const allUnicornsEatenOrRescued = unicorns.every(unicorn => unicorn.status === "eaten" || unicorn.status === 'rescued');
        if (allUnicornsEatenOrRescued) {
            return startNewLevel();
        }
        

        // check if the current position is burned otherwise move the dragon
        if (newGameBoard[currentDragonPosition[0]][currentDragonPosition[1]].isRevealed && !newGameBoard[currentDragonPosition[0]][currentDragonPosition[1]].isBurninated) {
            // 25% of the time burn the card, otherwise, move the dragon
            const randomNumber = Math.random();
            if (randomNumber < 0.25) {
                newGameBoard[currentDragonPosition[0]][currentDragonPosition[1]].isBurninated = true;
                updateGameArray(newGameBoard);
            } else {
                updateDragonPosition(newDragonPosition);
                dragonAttacksKnight();
            }
        } else {
            updateDragonPosition(newDragonPosition);
            dragonAttacksKnight();
        }
    }
    return {
        moveDragon,
    };
};