// GameContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { BoardLayout, Position, WildernessType } from './types'
import { v4 as uuidv9 } from 'uuid';
interface GameState {
  gameArray: BoardLayout;
  knightPosition: Position;
  meadowPosition: Position;
  unicorns: Unicorn[];
  dragonPosition: Position;
  gameInfoModal: boolean;
  gameOver: boolean;
  reachedMeadow: boolean;
  difficulty: string;
  gameStarted: boolean;
  gameOverReason: string;
  gameInfoText: string;
  day: number;
  // Add other state variables as needed
}

interface Unicorn {
    id: string;
    name: string;
    position: Position;
    status: string;
    shouldFollowKnight: boolean;
  }
interface GameContextValue extends GameState {
    updateKnightPosition: (newPosition: Position) => void;
    updateDragonPosition: (newPosition: Position) => void;
    updateGameOver: (newValue: boolean) => void;
    updateGameStarted: (newValue: boolean) => void;
  updateReachedMeadow: (newValue: boolean) => void;
  updateGameInfoModal: (newValue: boolean) => void;
  updateGameArray: (newGameArray: BoardLayout) => void;
  updateDifficulty: (newValue: string) => void;
  updateGameOverReason: (newValue: string) => void; 
  updateDay: (newValue: number) => void;
  updateUnicorns: (newUnicorns: Unicorn[]) => void;
  updateMeadowPosition: (newPosition: Position) => void;
  updateGameInfoText: (newValue: string) => void;
  }

const GameContext = createContext<GameContextValue | undefined>(undefined);

export const GameProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [gameState, setGameState] = useState<GameState>({
    gameArray: {
      "0": {
        "0": {
          "wildernessType": "Castle",
          "isBurninated": false,
          "isRevealed": false
        },
        "1": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        },
        "2": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        },
        "3": {
          "wildernessType": "Valley",
          "isBurninated": false,
          "isRevealed": false
        },
        "4": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        }
      },
      "1": {
        "0": {
          "wildernessType": "River",
          "isBurninated": false,
          "isRevealed": false
        },
        "1": {
          "wildernessType": "River",
          "isBurninated": false,
          "isRevealed": false
        },
        "2": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        },
        "3": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        },
        "4": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        }
      },
      "2": {
        "0": {
          "wildernessType": "Valley",
          "isBurninated": false,
          "isRevealed": false
        },
        "1": {
          "wildernessType": "Mountain",
          "isBurninated": false,
          "isRevealed": false
        },
        "2": {
          "wildernessType": "River",
          "isBurninated": false,
          "isRevealed": false
        },
        "3": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        },
        "4": {
          "wildernessType": "Mountain",
          "isBurninated": false,
          "isRevealed": false
        }
      },
      "3": {
        "0": {
          "wildernessType": "Mountain",
          "isBurninated": false,
          "isRevealed": false
        },
        "1": {
          "wildernessType": "Valley",
          "isBurninated": false,
          "isRevealed": false
        },
        "2": {
          "wildernessType": "Valley",
          "isBurninated": false,
          "isRevealed": false
        },
        "3": {
          "wildernessType": "Valley",
          "isBurninated": false,
          "isRevealed": false
        },
        "4": {
          "wildernessType": "Mountain",
          "isBurninated": false,
          "isRevealed": false
        }
      },
      "4": {
        "0": {
          "wildernessType": "Mountain",
          "isBurninated": false,
          "isRevealed": false
        },
        "1": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        },
        "2": {
          "wildernessType": "Mountain",
          "isBurninated": false,
          "isRevealed": false
        },
        "3": {
          "wildernessType": "Forest",
          "isBurninated": false,
          "isRevealed": false
        },
        "4": {
          "wildernessType": "Meadow",
          "isBurninated": false,
          "isRevealed": false
        }
      }
    },
    knightPosition: [0,0],
    unicorns: [
      {
        "id": uuidv9(), // Generate a unique ID for each unicorn,
        "position": [
          3,
          4
        ],
        "status": "wild",
        "name": "Steffi Patricia",
        "shouldFollowKnight": false
      },
      {
        "id": uuidv9(), // Generate a unique ID for each unicorn,
        "position": [
          4,
          4
        ],
        "status": "wild",
        "name": "Annette Saria",
        "shouldFollowKnight": false
      },
      {
        "id": uuidv9(), // Generate a unique ID for each unicorn,
        "position": [
          4,
          3
        ],
        "status": "wild",
        "name": "Saria Saria",
        "shouldFollowKnight": false
      }],
    dragonPosition: [0,4],
    gameInfoModal: false,
    gameOver: false,
    reachedMeadow: false,
    difficulty: "normal",
    gameStarted: false,
    gameOverReason: "",
    day: 1,
    meadowPosition: [4,4],
    gameInfoText: "Good luck, keep rescuing those unicorns!"
    // Initialize other state variables here
  });

  useEffect(() => {
    if (gameState.gameOver) {
      updateGameStarted(false); // Reset gameStarted to false
    }
  }, [gameState.gameOver]); 

  const updateDay = (newValue: number) => {
    setGameState(prevState => ({
      ...prevState,
      day: newValue,
    }));
  };

  const updateGameArray = (newGameArray: BoardLayout) => {
    setGameState(prevState => ({
      ...prevState,
      gameArray: newGameArray,
    }));
  };

  const updateDifficulty = (newValue: string) => {
    setGameState(prevState => ({
      ...prevState,
      difficulty: newValue,
    }));
  };

  const updateGameOverReason = (newValue: string) => {
    setGameState(prevState => ({
      ...prevState,
      gameOverReason: newValue,
    }));
  };
  const updateGameInfoText = (newValue: string) => {
    setGameState(prevState => ({
      ...prevState,
      gameInfoText: newValue,
    }));
  };
  const updateGameOver = (newValue: boolean) => {
    setGameState(prevState => ({
      ...prevState,
      gameOver: newValue,
    }));
  };

  const updateGameStarted = (newValue: boolean) => {
    setGameState(prevState => ({
      ...prevState,
      gameStarted: newValue,
    }));
  };

  const updateGameInfoModal = (newValue: boolean) => {
    setGameState(prevState => ({
      ...prevState,
      gameInfoModal: newValue,
    }));
  };
  
  const updateReachedMeadow = (newValue: boolean) => {
    setGameState(prevState => ({
      ...prevState,
      reachedMeadow: newValue,
    }));
  };


  const updateKnightPosition = (newPosition: Position) => {
    setGameState(prevState => ({
      ...prevState,
      knightPosition: newPosition,
    }));
  };

  const updateMeadowPosition = (newPosition: Position) => {
    setGameState(prevState => ({
      ...prevState,
      meadowPosition: newPosition,
    }));
  };

  const updateUnicorns = (newUnicorns: Unicorn[]) => {
    setGameState(prevState => ({
      ...prevState,
      unicorns: newUnicorns,
    }));
  };

  const updateDragonPosition = (newPosition: Position) => {
    setGameState(prevState => ({
      ...prevState,
      dragonPosition: newPosition,
    }));
  };

  return (
    <GameContext.Provider value={{ ...gameState, updateGameInfoText, updateMeadowPosition, updateDay, updateGameOverReason, updateGameStarted, updateDifficulty, updateGameInfoModal, updateKnightPosition, updateDragonPosition, updateUnicorns, updateGameOver, updateReachedMeadow, updateGameArray}}>
      {children}
    </GameContext.Provider>
  );
};

export const useGame = () => {
  const context = useContext(GameContext);
  if (!context) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
};