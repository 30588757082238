import Card from "./Card";
import { BoardLayout, Position, Unicorn } from '../types'


type GameBoardProps = {
  knightPosition: Position;
  dragonPosition: Position;
  moveKnight: (targetRow: number, targetCol: number) => void;
  gameArray: BoardLayout;
  unicorns: Unicorn[];
};

const GameBoard = ({
  gameArray,
  moveKnight,
  unicorns
}: GameBoardProps) => {
  return (
    <div id="gameboard">
      {Object.entries(gameArray).map(([i, row], rowIndex) =>
        Object.entries(row).map(([j, tileState], colIndex) => {
          let basicType;
          if (Number(i) === 0 && Number(j) === 0) {
            basicType = "castle";
          } else if (Number(i) === 4 && Number(j) === 4) {
            basicType = "meadow";
          } else {
            basicType = "wilderness";
          }
          return (
            <Card
              key={`${i}-${j}`}
              name={tileState.wildernessType}
              landType={basicType}
              rowNum={Number(i)}
              colNum={Number(j)}
              moveKnight={moveKnight}
              tileState={tileState}
            />
          );
        })
      )}
    </div>
  );
};

export default GameBoard;
