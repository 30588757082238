import { v4 as uuidv9 } from 'uuid';
import { useCallback, useEffect, useRef, useContext } from 'react'
import { BoardLayout, Position, WildernessType, Unicorn } from '../types'
import { ScoreContext } from '../components/ScoreContext'
import { useGame } from '../gameContext';
import { useMoveKnight } from '../logic/moveKnight';
import { useStartNewGame } from '../logic/startNewGame';
import { useMoveDragon } from '../logic/moveDragon';
import { useStartNewLevel } from '../logic/startNewLevel';


const DRAGON_TIMEOUT_MS = 30000;


const useGameBoard = () => {
	let {reachedMeadow, gameArray, knightPosition, unicorns, updateUnicorns} = useGame();
	const { startNewGame } = useStartNewGame();
	const { moveDragon } = useMoveDragon();
	const { moveKnight } = useMoveKnight();
	const {startNewLevel} = useStartNewLevel();
	const scoreContext = useContext(ScoreContext);
	if (!scoreContext) {
		throw new Error('ScoreContext is undefined');
	}
	const { score, setScore } = scoreContext;

	const timerRef = useRef<NodeJS.Timer | null>(null);
	
	const handleStartNewGame = useCallback(() => {
		startNewGame();
		setScore(0);
	  }, [gameArray]);

	
	const handleMoveKnight = useCallback((targetRow: number, targetCol: number) => {
		moveKnight(targetRow, targetCol); // Call moveKnight with the targetRow and targetCol
		// No need to call updateGameState here since moveKnight already updates the game state
	  }, [knightPosition, unicorns]); // Dependencies are now empty since moveKnight handles all updates
	

	
	  const lastScoreRef = useRef(0);

	  useEffect(() => {
		  // Check if the score is a multiple of 3 and is greater than the last score that triggered startNewLevel
		  if (score % 3 === 0 && score !== lastScoreRef.current) {
			  startNewLevel();
			  // Update the last score that triggered startNewLevel
			  lastScoreRef.current = score;
		  }
	  }, [score, startNewLevel]); // Add score and startNewLevel to the dependency array
 
	// move knight is the clickAction by the user, not setting the knightPosition

	

	/* //get all the possible moves the knight can make

	const getKnightMoves = useCallback((knightPosition: Position, gameArray: BoardLayout): Position[] => {
		const [knightRow, knightCol] = knightPosition;

		// Define the possible moves for a knight
		const moves = [
			[-1, 0], [1, 0], [0, -1], [0, 1]
		];

		// Initialize an empty array to store the possible moves
		let knightMoves: Position[] = [];

		// Check each possible move
		for (let move of moves) {
			const row = knightRow + move[0];
			const col = knightCol + move[1];

			// If the move is within the board and the destination is not burninated, add it to the possible moves
			if (row >= 0 && row < 5 && col >= 0 && col < 5 && !gameArray[row][col].isBurninated) {
				knightMoves.push([row, col]);
			}
		}

		return knightMoves;
	}, []);

	//check to see if the knight can move at all
	const canKnightMove = useCallback((knightPosition: Position, gameArray: BoardLayout) => {
		const knightMoves = getKnightMoves(knightPosition, gameArray);
		if (knightMoves[0]) {
			return true
		}
		// If no valid move was found, return false
		return false;
	}, [getKnightMoves]);


	const isCastleBlocked = (gameArray: BoardLayout) => {
		return gameArray[0][1].isBurninated && gameArray[1][0].isBurninated;
	};

	const isMeadowBlocked = (gameArray: BoardLayout) => {
		return gameArray[4][3].isBurninated && gameArray[3][4].isBurninated;
	};

 */

	 //Consolidated useEffect for updating the dragon position and resetting the timer
	useEffect(() => {
		
		// Clear any existing timer
		if (timerRef.current) {
			clearTimeout(timerRef.current);
		}

		// Set up a new timer to move the dragon after  30 seconds of inactivity
		timerRef.current = setTimeout(() => {
			moveDragon();
		}, DRAGON_TIMEOUT_MS);

		// Clean up the timer when the component unmounts or when the dependencies change
		return () => {
			if (timerRef.current) {
				clearTimeout(timerRef.current);
			}
		};
	}, [moveDragon]);


	// Consolidated useEffect for updating the score and checking game over conditions
	//useEffect(() => {
		// Update the score
	/* 	if (!gameArray || !Object.keys(gameArray).length) {
			return;
		} */
		
	


		/* // Check if the castle is blocked and end the game if necessary
		if (isCastleBlocked(gameArray)) {
			updateGameOver(true);
		}

		// Check if the meadow is blocked and end the game if necessary
		if (isMeadowBlocked(gameArray)) {
			updateGameOver(true);
		}

		// End the game if the knight can't make a valid move anymore
		if (!canKnightMove(knightPosition, gameArray)) {
			updateGameOver(true);
		} */
	//}, [knightPosition, unicorns, reachedMeadow, setScore, updateUnicorns] );
 

	return {
		moveKnight: handleMoveKnight,
		startNewGame: handleStartNewGame
	};
}


export default useGameBoard;
