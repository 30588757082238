import { TileState } from '../types'
import { useGame } from '../gameContext';
import { useEffect } from 'react'
type CardProps = {
  rowNum: number;
  colNum: number;
  landType: string;
  name: string;
  imgPath?: string;
  moveKnight(targetRow: number, targetCol: number): void;
  key: string | number;
  tileState: TileState;
};
const Card = (
  props: CardProps
) => {
  const { knightPosition, unicorns, dragonPosition } = useGame();
   
  const isKnightHere =
    props.rowNum === knightPosition[0] &&
    props.colNum === knightPosition[1];


    const isUnicornHere = unicorns && unicorns.some(unicorn =>
      unicorn.position && unicorn.position.length >=  2 &&
      unicorn.position[0] === props.rowNum &&
      unicorn.position[1] === props.colNum &&
      unicorn.status === 'wild'
    );

  const isDragonHere =
    props.rowNum === dragonPosition[0] &&
    props.colNum === dragonPosition[1];


  const handleCardClick = () => {
    props.moveKnight(props.rowNum, props.colNum);
  };

  return (
    <div
      className={`card ${
        props.tileState.isRevealed
          ? props.name.toLowerCase()
          : props.landType.toLowerCase()
      }${
        props.tileState.isBurninated
          ? "-destroyed"
          : ""
      }`}
      onClick={handleCardClick}
    >
      <div className="imgContainer">
        {isUnicornHere && (
          <img src="../images/unicorn2.png" className="unicorn" alt="unicorn" />
        )}
        {isKnightHere && (
          <img src="../images/knight.png" className="knight" alt="knight" />
        )}
        {isDragonHere && (
          <img src="../images/dragon.png" className="dragon" alt="dragon" />
        )}
      </div>
    </div>
  );
};
export default Card;
