import { v4 as uuidv9 } from 'uuid';
import { BoardLayout, Position, WildernessType, Unicorn } from '../types'
import { useGame } from '../gameContext';

export const useStartNewLevel = () => {

    const { gameArray,
        unicorns,
        knightPosition,
        dragonPosition,
        day,
        meadowPosition,
        difficulty,
        updateUnicorns,
        updateGameArray,
        updateDay,
        updateMeadowPosition,
        updateGameStarted,
        updateGameOver,
        updateKnightPosition,
        updateReachedMeadow,
        updateDragonPosition,
        updateGameInfoModal,
        updateGameInfoText
     } = useGame();
    const startNewLevel = () => {
        const randomName = () => {
            // pick a name from the list
            let names = ["Steffi", "Teresa", "Saria", "Reena", "Annette", "Frida", "Patricia", "Maire"]
            // combine and return two random names from the list
            return `${names[Math.floor(Math.random() * names.length)]} ${names[Math.floor(Math.random() * names.length)]}`;
        }

        const setUnicornMeadowPosition =  () => {
            if (difficulty === 'normal') {
                updateMeadowPosition([4,4])
            }
			if (difficulty === 'hard') {
				// Choose a random position from the array of hard difficulty spots
				const hardSpots = [[2, 3], [2, 4], [3, 3], [3, 4], [4, 2], [4, 3], [4, 4]]; // Define the array of hard difficulty spots
				let hardMeadowPosition = hardSpots[Math.floor(Math.random() * hardSpots.length)] as Position;
				updateMeadowPosition(hardMeadowPosition);
			} 
            if (difficulty === 'challenge') {
				// Choose a random position from the array of challenge difficulty spots
				const challengeSpots = [[3, 3], [3, 4], [4, 3], [4, 4]] // Define the array of challenge difficulty spots
				let challengeMeadowPosition = challengeSpots[Math.floor(Math.random() * challengeSpots.length)] as Position;
				updateMeadowPosition(challengeMeadowPosition);
			}
		};

        const getPositionsAroundMeadow = (meadowPosition: Position): Position[] => {
            const [meadowRow, meadowCol] = meadowPosition;
            const spots = [
                [-1, 0], [1, 0], [0, -1], [0, 1] // Up, Down, Left, Right
            ];
            let positionsAroundMeadow: Position[] = [];


            spots.forEach(([rowOffset, colOffset]) => {
                const newRow = meadowRow + rowOffset;
                const newCol = meadowCol + colOffset;

                // Check if the new position is within the board
                if (newRow >= 0 && newRow < 5 && newCol >= 0 && newCol < 5) {
                    positionsAroundMeadow.push([newRow, newCol]);
                }
            });
            positionsAroundMeadow.push([meadowRow, meadowCol]);


            let spotsCopy = [...positionsAroundMeadow]; // Fixed: make a true copy of the array

            let magicUnicornThree: Position[] = [];

            for (let i = 0; i < 3; i++) {
                const selectedMeadowPosition = spotsCopy[Math.floor(Math.random() * spotsCopy.length)] as Position;

                magicUnicornThree.push(selectedMeadowPosition);
                // Remove the selected spot to avoid picking it again
                spotsCopy = spotsCopy.filter(pos => pos[0] !== selectedMeadowPosition[0] || pos[1] !== selectedMeadowPosition[1]);
            }
            console.log(`${magicUnicornThree}`)
            return magicUnicornThree;
        };

        const placeUnicorns = (day: number) => {
            let unicornPositions = getPositionsAroundMeadow(meadowPosition);
            console.log(`unicorns in placeunicorns: ${unicornPositions}`);
            // Define the positions for unicorns based on the day number and difficulty level
            // Define the positions for unicorns
            if (day === 1) {
                // Place unicorns on or near the unicorn meadow
                // Update the game state with the new unicorn positions
                let newUnicorns = unicornPositions.map(position => ({
                    id: uuidv9(), // Generate a unique ID for each unicorn
                    position,
                    status: "wild",
                    name: randomName(),
                    shouldFollowKnight: false
                }));
                console.log(JSON.stringify(newUnicorns, null, 2));
                updateUnicorns(newUnicorns);

            } else if (day >= 2) {
               console.log('day 2 or greater');
                // Place unicorns on random positions not near the dragon 
                const getAllPositions = () => {
                    const rows = Array.from({ length: 5 }, (_, i) => i);
                    const cols = Array.from({ length: 5 }, (_, i) => i);
                    return rows.flatMap((row) => cols.map((col) => [row, col]));
                };
                const allPositions = getAllPositions();
                let validPositions = allPositions.filter(
                    ([row, col]) => !(row === 0 && col === 0) && !(row === dragonPosition[0] && col === dragonPosition[1])
                );

                let spotsCopy = [...validPositions]; // Fixed: make a true copy of the array

                let magicUnicornThree: Position[] = [];

                for (let i = 0; i < 3; i++) {
                    const selectedMeadowPosition = spotsCopy[Math.floor(Math.random() * spotsCopy.length)] as Position;

                    magicUnicornThree.push(selectedMeadowPosition);
                    // Remove the selected spot to avoid picking it again
                    spotsCopy = spotsCopy.filter(pos => pos[0] !== selectedMeadowPosition[0] || pos[1] !== selectedMeadowPosition[1]);
                }



                let unicornPositions = magicUnicornThree;

                let newUnicorns = unicornPositions.map(position => ({
                    id: uuidv9(), // Generate a unique ID for each unicorn
                    position,
                    status: "wild",
                    name: randomName(),
                    shouldFollowKnight: false

                }));
                console.log(JSON.stringify(newUnicorns, null, 2));
                updateUnicorns(newUnicorns);
            }

        }

        const resetGameArray = () => {
            // new game array defined the WildernessTypes for the cards
            let newGameArray: BoardLayout = {};
            updateMeadowPosition(meadowPosition);
            const wildernessTypes: WildernessType[] = [
                "Forest",
                "Valley",
                "River",
                "Mountain",
            ];

            for (let i = 0; i < 5; i++) {
                for (let j = 0; j < 5; j++) {
                    let cardType = Math.floor(Math.random() * wildernessTypes.length);
                    if (!newGameArray[i]) {
                        newGameArray[i] = {};
                    }
                    if (!newGameArray[i][j]) {
                        newGameArray[i][j] = {
                            wildernessType: "Forest",
                            isBurninated: false,
                            isRevealed: false
                        };
                    }
                    if (i === 0 && j === 0) {
                        newGameArray[i][j].wildernessType = "Castle";
                    } else if (i === meadowPosition[0] && j === meadowPosition[1]) {
                        newGameArray[i][j].wildernessType = "Meadow";
                    } else {
                        newGameArray[i][j].wildernessType = wildernessTypes[cardType];
                    }
                }
            }
            updateGameArray(newGameArray);
        };


        // Reset game board and knight position
        setUnicornMeadowPosition();
        updateReachedMeadow(false);    
        updateKnightPosition([0, 0]); // Reset knight position
        updateDragonPosition([0, 4]); // reset the dragon position
      
        updateGameStarted(true);
        updateDay(day+1);
        placeUnicorns(day+1);
        resetGameArray();
        updateGameOver(false);
        updateGameInfoText("A New Day has started. The rain put out the fires and more unicorns were spotted!");
        updateGameInfoModal(true);
    }
    return {
        startNewLevel
    }
}
