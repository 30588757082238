import { useCallback, useContext } from 'react';
import { BoardLayout, Position, WildernessType, Unicorn } from '../types'
import { useGame } from '../gameContext';
import { useMoveDragon } from './moveDragon';
import { ScoreContext } from '../components/ScoreContext'


export const useMoveKnight = () => {
    const { updateReachedMeadow, meadowPosition, reachedMeadow, updateGameOverReason, updateGameArray, dragonPosition, unicorns, updateUnicorns, gameArray, knightPosition, updateKnightPosition, updateDragonPosition, updateMeadowPosition, updateGameOver, updateGameStarted, updateDay } = useGame();
    const { moveDragon } = useMoveDragon();
    const scoreContext = useContext(ScoreContext);
	if (!scoreContext) {
		throw new Error('ScoreContext is undefined');
	}
	const { setScore } = scoreContext;

    const moveKnight = useCallback((targetRow: number, targetCol: number) => {
        // this should be handled elsewhere but it was a hack to prevent the user from moving the knight when the game was over
        /* if (gameOver) {
            return;
        } */
        console.log(JSON.stringify(gameArray, null, 2));


        const [currentRow, currentCol] = knightPosition;


        // knight can only move up/down/left/right
        const isAdjacentRow = Math.abs(currentRow - targetRow) === 1 && currentCol === targetCol;
        const isAdjacentCol = Math.abs(currentCol - targetCol) === 1 && currentRow === targetRow;
        const knightTargetPosition: Position = [targetRow, targetCol];
        console.log(`current position:${knightPosition}  target move: ${knightTargetPosition}`);
        console.log(`target move column adjacent?: ${isAdjacentCol}`);
        console.log(`target move row adjacent?: ${isAdjacentRow}`);

        let newGameBoard = gameArray;
        if (isAdjacentRow || isAdjacentCol) {

            console.log('target move adjacent');

            // knight can't move to destroyed cards
            // check target tile in gameArray for isDestroyed true
            if (newGameBoard[targetRow][targetCol].isBurninated) {
                // then don't move the knight
                return;
            }

            // Only update the state if the new position is different from the current position
            if (currentRow !== targetRow || currentCol !== targetCol) {
                console.log('target move different');

                //const newKnightPosition: Position = [targetRow, targetCol]

                updateKnightPosition([targetRow, targetCol]);
                console.log('knight position updated');
                // update map state to isRevealed: true

                newGameBoard[targetRow][targetCol].isRevealed = true;

                updateGameArray(newGameBoard);
                console.log('game board position set as revealed');

                
                // if we moved to the meadow, set reachedMeadow to true
                console.log((`current meadow position ${meadowPosition}`))
                if (!reachedMeadow && meadowPosition[0] === targetRow && meadowPosition[1] === targetCol) {
                    console.log(`updatiing reached meadow`);
                    updateReachedMeadow(true);

                }

                // Find the unicorn at the target position
                const unicornAtPosition = unicorns && unicorns.find(unicorn =>
                    unicorn.position && unicorn.position.length >= 2 &&
                    unicorn.position[0] === targetRow && unicorn.position[1] === targetCol
                );

                const updateFollowingUnicorns = () => {
                    let knightTargetPosition = [targetRow, targetCol] as Position;
                    const followingUnicorns = unicorns.map(unicorn =>
                        unicorn.status === 'wild' && unicorn.shouldFollowKnight ? { ...unicorn, position: knightTargetPosition } : unicorn
                    );
                    // Update the unicorns in the game state
                    updateUnicorns(followingUnicorns);
                    console.log(JSON.stringify(followingUnicorns, null, 2));
                } 


                if (reachedMeadow) {
                    console.log(`reached meadow is true`);
                    // check for any following unicorns
                    const alreadyFollowing = unicorns.some(unicorn => unicorn.shouldFollowKnight && unicorn.status === 'wild');
                    if (alreadyFollowing) {
                        console.log(`there is an unicorn already following, update the unicorns to follow`);
                        updateFollowingUnicorns();
                    } else if (unicornAtPosition && unicornAtPosition.status === 'wild') {
                        console.log('unicorn at position');
                        const updatedUnicorns = unicorns.map(unicorn =>
                            unicorn.position[0] === targetRow && unicorn.position[1] === targetCol
                                ? { ...unicorn, shouldFollowKnight: true }
                                : unicorn
                        );
                        console.log(JSON.stringify(updatedUnicorns, null, 2));
                        // Update the unicorns
                        updateUnicorns(updatedUnicorns);
                        console.log('these unicorns should be updated with 1 following');
                        console.log(JSON.stringify(unicorns, null, 2));
                    }
                    
                
                    //the scoring logic needs fixing
                    if (targetRow ===  0 && targetCol ===  0 && alreadyFollowing) {
                        console.log('yes already following, lets update the score')
                        const rescuedUnicorns = unicorns.map(unicorn => {
                        if (unicorn.shouldFollowKnight) {
                            return { ...unicorn, status: "rescued", shouldFollowKnight: false };
                        }
                        return unicorn;
                        });
                        updateUnicorns(rescuedUnicorns);
                        setScore((prevScore: number) => prevScore + 1);
                    
                    } 

                }

                console.log(`dragon position?: ${dragonPosition}`);


                // if the knight has attacked the dragon, 75% off the time game over, otherwise move the dragon
                if (dragonPosition[0] === targetRow && dragonPosition[1] === targetCol) {
                    const randomNumber = Math.random();
                    if (randomNumber < 0.75) {
                        updateGameOverReason("You foolishly attacked the dragon and lost");
                        updateGameOver(true);
                    } else {
                        moveDragon();
                        return;
                    }
                }

                // and move the dragon
                moveDragon();


            }
        }
    }, [gameArray, updateKnightPosition, updateGameArray, knightPosition, unicorns]);
    return {
        moveKnight
    }
};