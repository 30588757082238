// DifficultySelector.tsx
import React from 'react';
import { useGame } from '../gameContext';

const DifficultySelector = () => {
  const { difficulty, updateDifficulty, gameStarted } = useGame();

  const handleDifficultyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    updateDifficulty(event.target.value);
  };
  if (gameStarted) {
    return null;
  }
  
  return (
    <select value={difficulty} onChange={handleDifficultyChange}>
      <option value="normal">Normal</option>
      <option value="hard">Hard</option>
      <option value="challenge">Challenge</option>
    </select>
  );
};

export default DifficultySelector;