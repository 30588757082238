import React, { useState, ReactNode } from 'react';
import { ScoreContext } from './ScoreContext'

interface ScoreProviderProps {
 children: ReactNode;
}

export const ScoreProvider = ({ children }: ScoreProviderProps) => {
 const [score, setScore] = useState(0);

 return (
   <ScoreContext.Provider value={{ score, setScore }}>
     {children}
   </ScoreContext.Provider>
 );
};