
import { useGame } from '../gameContext';
const GameOverModal = () => {
  const { gameOverReason } = useGame();
 return (
   <div className="gameover">
     <h1>{gameOverReason}</h1>
   </div>
 );
};

export default GameOverModal;
